html[data-theme="light"] {
    --astro-table-border: #bdbdbd;
    --astro-table-border-light: #e0e0e0;

    --aspects-table-border: rgba(65, 65, 65, 0.08);
    --aspects-table-border-light: rgba(0, 0, 0, 0.2);
    --aspects-table-background-hover: rgba(65, 65, 65, 0.08);
    --aspects-table-background-current: #ffdbdb;

    --background: #f9f9f9;

    --button-bg: #ffffff;
    --button-border: rgba(88, 88, 88, 0.2);

    --bg-100: #ffffff;
    --bg-400: #f6f9fb;
    --bg-410: #f6f9fb;
    --bg-500: #f6f9fb;
    --bg-600: #dedede;
    --bg-share: #f7f5f5;
    --bg-share-hover: rgba(0, 0, 0, 0.04);

    --bg-session: #ffffff;
    --bg-interval: #f0f0f0;
    --bg-break: repeating-linear-gradient(-45deg, #ffffff 0px, #ffffff 4px, #f0f0f0 4px, #f0f0f0 8px);

    --bg-700: #ffffff;
    --bg-sidebar: #f0f0f0;

    --circle-internal-background: #f6f9fb;
    --circle-border: #b0bec5;
    --circle-zodiacs-border: #c9d6dc;
    --circle-zodiacs-background: #f6f9fb;
    --circle-zodiacs-icons-land: var(--circle-zodiacs-elements-common);
    --circle-zodiacs-icons-air: var(--circle-zodiacs-elements-common);
    --circle-zodiacs-icons-water: var(--circle-zodiacs-elements-common);
    --circle-zodiacs-icons-fire: var(--circle-zodiacs-elements-common);
    --circle-zodiacs-sectors-display-light: block;

    --circle-zodiacs-elements-air: #9575cd;

    --circle-zodiacs-elements-air-rgb: 149, 117, 205;

    --circle-houses-usual: rgba(0, 0, 0, 0.37);
    --circle-houses-special: rgba(0, 0, 0, 0.87);
    --circle-houses-alternative: #7986cb;

    --circle-houses-usual-rgb: 161, 161, 161;
    --circle-houses-special-rgb: 33, 33, 33;
    --circle-houses-alternative-rgb: 121, 134, 203;

    --circle-aspects-90: #191919;
    --circle-aspects-180: #191919;

    --circle-aspects-90-rgb: 19, 19, 19;
    --circle-aspects-180-rgb: 19, 19, 19;

    --dashboard-border: rgb(224, 224, 224);
    --dashboard-search-placeholder: #171717;
    --dashboard-search-background: #f6f9fb;

    --dashboard-card-pinned: #ffffff;
    --dashboard-card-overlay: #000000;
    --dashboard-card-person: #ffffff;
    --dashboard-card-person-inverse: #000000;
    --dashboard-card-shadow: none;
    --dashboard-card-border: rgba(0, 0, 0, 0.2);

    --dropdown-background: #ffffff;
    --dropdown-color: #3c3c3c;

    --element: rgba(88, 88, 88, 0.2);
    --element-light: rgba(255, 255, 255, 0);
    --element-neutral: rgba(65, 65, 65, 0.08);
    --element-neutral-hover: rgba(65, 65, 65, 0.12);
    --element-neutral-focus: rgba(65, 65, 65, 0.14);
    --element-disabled: rgba(65, 65, 65, 0.05);
    --element-neo: rgba(255, 255, 255, 0.05);

    --bg-element-hover: rgba(0, 0, 0, 0.05);
    --bg-element-hover-hover: rgba(65, 65, 65, 0.05);

    --icon-primary: rgba(0, 0, 0, 0.87);
    --icon-secondary: rgba(0, 0, 0, 0.6);
    --icon-third: rgba(0, 0, 0, 0.37);

    /*--input-background: rgba(0, 0, 0, 0.15);
  --input-border: rgba(255, 255, 255, 0.15);
  --input-background-active: #AEAEAE;*/
    --input-background: rgba(255, 255, 255, 0.15);
    --input-border: rgba(0, 0, 0, 0.15);
    --input-border-hover: rgba(0, 0, 0, 0.24);
    --input-border-focus: rgba(0, 0, 0, 0.34);
    --input-background-active: #4c4c4c;

    --layer-color: rgba(167, 167, 167, 0.2);
    --disabled-layer-color: rgba(167, 167, 167, 0.2);

    --aside-gradient: linear-gradient(0deg, rgba(244, 244, 244, 0.6), rgba(244, 244, 244, 0.7), rgba(244, 244, 244, 0.9));

    --modal-bg: rgba(255, 255, 255, 0.78);

    --notifications-background: #f6f9fb;

    --orbise-thumb-icon: url("data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><circle cx='12' cy='12' r='12' fill='black' fill-opacity='0.87'/><path fill-rule='evenodd' clip-rule='evenodd' d='M9.89673 9.77786L7.67451 12.0001L9.89673 14.2223L9.11105 15.008L6.10316 12.0001L9.11105 8.99219L9.89673 9.77786Z' fill='white'/><path fill-rule='evenodd' clip-rule='evenodd' d='M14.1033 9.77786L16.3255 12.0001L14.1033 14.2223L14.8889 15.008L17.8968 12.0001L14.8889 8.99219L14.1033 9.77786Z' fill='white'/></svg>");

    --rectification-block-background: rgba(135, 135, 135, 0.05);
    --rectification-block-border: rgba(65, 65, 65, 0.08);
    --rectification-block-color: rgba(0, 0, 0, 0.6);

    --rectification-block2-background: none;
    --rectification-block2-background-hover: rgba(65, 65, 65, 0.08);
    --rectification-block2-border: rgba(88, 88, 88, 0.2);
    --rectification-block2-color: rgba(0, 0, 0, 0.87);

    --text-primary: rgba(0, 0, 0, 0.87);
    --text-secondary: rgba(0, 0, 0, 0.6);
    --text-third: rgba(0, 0, 0, 0.37);
    --text-inverse: #000000;
    --text-white: rgba(255, 255, 255, 0.87);

    --workspace-background: #f9f9f9;
    --workspace-widget-background: white;

    --workspace-box-shadow: 0px 3.2px 7.2px rgba(90, 91, 106, 0.24), 0px 0.6px 1.8px rgba(58, 58, 68, 0.24);

    --layer-gradient: #fff 0%, rgba(59, 59, 59, 0) 100%;

    --switcher-background: rgba(167, 167, 167, 0.2);
    --switcher-button-background: white;

    /*Customizations*/

    /*houses*/
    /*--circle-houses-special-rgb-0: 0, 0, 0;*/
    /*--circle-houses-alternative-rgb-0: 239,83,80;*/

    --circle-houses-special-rgb-1: 0, 0, 0;
    --circle-houses-usual-rgb-1: 112, 112, 112;

    /*--circle-houses-usual-rgb-2: 112, 112, 112;*/

    /*aspects*/
    --circle-aspects-45-rgb-0: 0, 0, 0;
    --circle-aspects-90-rgb-0: 0, 0, 0;
    --circle-aspects-135-rgb-0: 0, 0, 0;
    --circle-aspects-150-rgb-0: 0, 0, 0;
    --circle-aspects-180-rgb-0: 0, 0, 0;
}
