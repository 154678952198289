@media print {
    html,
    body {
        margin: 0 !important;
        padding: 0 !important;
        // font-size: 1.1em;
    }

    @page {
        size: A4 !important;
        margin: 0.5cm 3rem !important;
    }

    .no-print {
        display: none !important;
    }

    .maps-for-print {
        height: 14cm !important;
        margin-top: 8rem;
        margin-right: 0 !important;
        margin-left: 0 !important;
        padding: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        transform: translate(0, 0) !important;

        & > div {
            max-width: 100%;
            max-height: 100%;
            justify-content: center;
            margin-top: 5rem;

            svg {
                max-width: 85% !important;
                max-height: 85% !important;
            }
        }
    }

    body[title] .maps-for-print {
        margin-top: 12rem;
    }

    .map-wrapper {
        animation: inherit !important;
    }

    body::after {
        box-sizing: border-box;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        content: attr(title);
        font-size: 1.5rem;
        line-height: 2.5rem;
        padding: 4rem;
    }
}

@media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
        body[title] .maps-for-print {
            // transform: scale(1.1, 1.1);
            margin-top: 5rem;
        }
    }
}
