/* Dark theme by default */

html {
    --accent-blue: #4093f4;
    --accent-blue-hover: #669fee;
    --accent-red: #ef5350;
    --accent-magenta: #ba68c8;
    --accent-green: #73b330;
    --accent-orange: #ed682e;
    --accent-purple: #ba68c8;
    --accent-usual: #7986cb;
    --accent-gray: #707070;
    --accent-yellow: #f6c40f;
    --accent-pink: #ec307f;

    --accent-color: var(--accent-blue);

    --astro-table-border: #4c4c4c;
    --astro-table-border-light: #333333;

    --astro-table-object-major: #73b330;

    --aspects-table-border: #4b4b4b;
    --aspects-table-border-light: #626262;
    --aspects-table-background-hover: #444444;
    --aspects-table-background-current: #5d4d4d;

    --background: #1d1d1d;

    --bg-100: #151515;
    --bg-200: #1d1d1d;
    --bg-300: rgba(34, 34, 34, 1);
    --bg-400: #2a2a2a;
    --bg-410: #2a2a2a;
    --bg-500: #333333;
    --bg-600: #3b3b3b;
    --bg-700: #4c4c4c;
    --bg-sidebar: #2a2a2a;
    --bg-clear-modal: #1b1b1b;
    --bg-share: #151515;
    --bg-share-hover: rgba(255, 255, 255, 0.08);
    --bg-hotkey: #616161;
    --bg-session: #2d2d2d;
    --bg-interval: #222222;
    --bg-break: repeating-linear-gradient(-45deg, #222222 0px, #222222 4px, #151515 4px, #151515 8px);

    --button-bg: rgba(255, 255, 255, 0.05);
    --button-bg-hover: rgba(255, 255, 255, 0.15);
    --button-border: rgba(255, 255, 255, 0.08);

    --circle-internal-background: #1d1d1d;
    --circle-border: #555555;

    --circle-zodiacs-border: var(--circle-border);
    --circle-zodiacs-background: #262626;
    --circle-zodiacs-elements-common: rgba(33, 33, 33, 0.6);
    --circle-zodiacs-elements-land: #66bb6a;
    --circle-zodiacs-elements-air: #e2eef9;
    --circle-zodiacs-elements-water: #537dcf;
    --circle-zodiacs-elements-fire: #ff8a65;
    --circle-zodiacs-icons-land: var(--circle-zodiacs-elements-land);
    --circle-zodiacs-icons-air: var(--circle-zodiacs-elements-air);
    --circle-zodiacs-icons-water: var(--circle-zodiacs-elements-water);
    --circle-zodiacs-icons-fire: var(--circle-zodiacs-elements-fire);
    --circle-zodiacs-sectors-display-light: none;

    --circle-zodiacs-elements-common-rgb: 33, 33, 33, 0.6;
    --circle-zodiacs-elements-land-rgb: 102, 187, 106;
    --circle-zodiacs-elements-air-rgb: 226, 238, 249;
    --circle-zodiacs-elements-water-rgb: 83, 125, 207;
    --circle-zodiacs-elements-fire-rgb: 255, 138, 101;
    --circle-zodiacs-icons-land-rgb: var(--circle-zodiacs-elements-land-rgb);
    --circle-zodiacs-icons-air-rgb: var(--circle-zodiacs-elements-air-rgb);
    --circle-zodiacs-icons-water-rgb: var(--circle-zodiacs-elements-air-rgb);
    --circle-zodiacs-icons-fire-rgb: var(--circle-zodiacs-elements-fire-rgb);

    --circle-houses-usual: #ffffff;
    --circle-houses-special: #ef5350;
    --circle-houses-alternative: #7986cb;

    --circle-houses-usual-rgb: 255, 255, 255;
    --circle-houses-special-rgb: 239, 83, 80;
    --circle-houses-alternative-rgb: 121, 134, 203;

    --circle-aspects-0: #328bdc;
    --circle-aspects-30: rgba(255, 0, 0, 0.25);
    --circle-aspects-36: green;
    --circle-aspects-40: pink;
    --circle-aspects-45: lightgray;
    --circle-aspects-60: #ef5350;
    --circle-aspects-72: lightgreen;
    --circle-aspects-80: pink;
    --circle-aspects-90: #ffffff;
    --circle-aspects-100: pink;
    --circle-aspects-108: green;
    --circle-aspects-120: #ef5350;
    --circle-aspects-135: lightgray;
    --circle-aspects-144: green;
    --circle-aspects-150: lightgray;
    --circle-aspects-180: #ffffff;

    --circle-aspects-0-rgb: 50, 139, 220;
    --circle-aspects-30-rgb: 255, 0, 0;
    --circle-aspects-36-rgb: 0, 128, 0;
    --circle-aspects-40-rgb: 255, 192, 203;
    --circle-aspects-45-rgb: 211, 211, 211;
    --circle-aspects-60-rgb: 239, 83, 80;
    --circle-aspects-72-rgb: 144, 238, 144;
    --circle-aspects-80-rgb: 255, 192, 203;
    --circle-aspects-90-rgb: 255, 255, 255;
    --circle-aspects-100-rgb: 255, 192, 203;
    --circle-aspects-108-rgb: 0, 128, 0;
    --circle-aspects-120-rgb: 239, 83, 80;
    --circle-aspects-135-rgb: 211, 211, 211;
    --circle-aspects-144-rgb: 0, 128, 0;
    --circle-aspects-150-rgb: 211, 211, 211;
    --circle-aspects-180-rgb: 255, 255, 255;

    --colors-blue: #4093f4;
    --colors-red: #ef5350;
    --colors-purple: #ba68c8;
    --colors-green: #73b230;
    --colors-orange: #ed682e;
    --colors-white: white;
    --colors-yellow: #f59300;
    --colors-pink: #F96AA2;
    --colors-violet: #9A69FD;
    --colors-green-pear: #DCF833;

    --dashboard-border: #333333;
    --dashboard-search-placeholder: #a8a8a8;
    --dashboard-search-background: #171717;

    --dashboard-card-pinned: #ffffff;
    --dashboard-card-overlay: #ffffff;
    --dashboard-card-person: #ffffff;
    --dashboard-card-person-inverse: #ffffff;
    --dashboard-card-shadow: none;
    --dashboard-card-border: none;

    --dialog-background: #4c4c4c;

    --dropdown-background: #141414;
    --dropdown-color: #acacac;

    --element: rgba(255, 255, 255, 0.2);
    --element-light: rgba(255, 255, 255, 0.1);
    --element-neutral: rgba(255, 255, 255, 0.08);
    --element-neutral-hover: rgba(255, 255, 255, 0.12);
    --element-neutral-focus: rgba(255, 255, 255, 0.14);
    --element-disabled: rgba(255, 255, 255, 0.05);
    --element-neo: rgba(255, 255, 255, 0.05);
    --element-selected: linear-gradient(0deg, rgba(76, 154, 245, 0.12), rgba(76, 154, 245, 0.12)), rgba(255, 255, 255, 0.08);

    --bg-element-hover: rgba(255, 255, 255, 0.05);
    --bg-element-hover-hover: rgba(255, 255, 255, 0.08);

    --font-primary: #ffffff;
    --font-inverse: #424242;

    --icon-primary: rgba(255, 255, 255, 0.87);
    --icon-secondary: rgba(255, 255, 255, 0.6);
    --icon-third: rgba(255, 255, 255, 0.37);

    --input-background: rgba(0, 0, 0, 0.15);
    --input-background-highlighted:rgba(255, 255, 255, 0.15);
    --input-border: rgba(255, 255, 255, 0.15);
    --input-border-hover: rgba(255, 255, 255, 0.24);
    --input-border-focus: rgba(255, 255, 255, 0.34);
    --input-background-active: #4c4c4c;

    --layer-color: rgba(0, 0, 0, 0.2);
    --layer-red-color: rgba(239, 83, 80, 0.08);
    --disabled-layer-color: rgba(29, 29, 29, 0.8);

    --aside-gradient: linear-gradient(0deg, rgba(45, 45, 45, 0.6), rgba(45, 45, 45, 0.6)), rgba(63, 63, 63, 0.95);

    --modal-bg: rgba(255, 255, 255, 0.08);

    --drag-preview-bg: rgba(0, 0, 0, 0.08);
    --drag-preview-border: rgba(255, 255, 255, 0.06);

    --orbise-thumb-icon: url("data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><circle cx='12' cy='12' r='12' fill='white' fill-opacity='0.87'/><path fill-rule='evenodd' clip-rule='evenodd' d='M9.89673 9.77786L7.67451 12.0001L9.89673 14.2223L9.11105 15.008L6.10316 12.0001L9.11105 8.99219L9.89673 9.77786Z' fill='black'/><path fill-rule='evenodd' clip-rule='evenodd' d='M14.1033 9.77786L16.3255 12.0001L14.1033 14.2223L14.8889 15.008L17.8968 12.0001L14.8889 8.99219L14.1033 9.77786Z' fill='black'/></svg>");
    --notifications-background: #171717;

    --rectification-block-background: rgba(255, 255, 255, 0.05);
    --rectification-block-border: rgba(255, 255, 255, 0.08);
    --rectification-block-color: rgba(255, 255, 255, 0.6);

    --rectification-block2-background: rgba(0, 0, 0, 0.2);
    --rectification-block2-background-hover: rgba(255, 255, 255, 0.08);
    --rectification-block2-border: rgba(255, 255, 255, 0.2);
    --rectification-block2-color: rgba(255, 255, 255, 0.87);

    --text-primary: rgba(255, 255, 255, 0.87);
    --text-secondary: rgba(255, 255, 255, 0.6);
    --text-third: rgba(255, 255, 255, 0.37);
    --text-inverse: #ffffff;
    --text-alert: rgba(255, 255, 255, 0.87);
    --text-white: rgba(255, 255, 255, 0.87);

    --warning-color: #ed682e;
    --warning-window-background: rgba(245, 147, 0, 0.05);
    --warning-window-border: rgba(245, 147, 0, 0.5);

    --workspace-background: #191919;
    --workspace-widget-background: #3b3b3b;

    --workspace-box-shadow: 0px 3.2px 7.2px rgba(0, 0, 0, 0.4), 0px 0.6px 1.8px rgba(0, 0, 0, 0.2);

    --easeOutback: cubic-bezier(0.34, 1.56, 0.64, 1);
    --easeOutCirc: cubic-bezier(0, 0.55, 0.45, 1);
    --easeOutQuart: cubic-bezier(0.25, 1, 0.5, 1);

    --switcher-background: rgba(0, 0, 0, 0.2);
    --switcher-button-background: rgba(255, 255, 255, 0.08);

    /*Customizations*/

    /*houses*/
    --circle-houses-usual-rgb-0: 112, 112, 112;
    --circle-houses-special-rgb-0: 239, 83, 80;
    --circle-houses-alternative-rgb-0: 100, 181, 246;

    --circle-houses-usual-rgb-1: 112, 112, 112;
    --circle-houses-special-rgb-1: 112, 112, 112;
    --circle-houses-alternative-rgb-1: 100, 181, 246;

    /*--circle-houses-usual-rgb-2: 236,236,236;*/
    /*--circle-houses-special-rgb-2: 239,83,80;*/
    /*--circle-houses-alternative-rgb-2: 100,181,246;*/

    /*aspects*/
    --circle-aspects-0-rgb-0: 64, 147, 244;
    --circle-aspects-30-rgb-0: 239, 83, 80;
    --circle-aspects-36-rgb-0: 102, 187, 106;
    --circle-aspects-40-rgb-0: 239, 83, 80;
    --circle-aspects-45-rgb-0: 255, 255, 255;
    --circle-aspects-60-rgb-0: 239, 83, 80;
    --circle-aspects-72-rgb-0: 102, 187, 106;
    --circle-aspects-80-rgb-0: 239, 83, 80;
    --circle-aspects-90-rgb-0: 255, 255, 255;
    --circle-aspects-100-rgb-0: 239, 83, 80;
    --circle-aspects-108-rgb-0: 102, 187, 106;
    --circle-aspects-120-rgb-0: 239, 83, 80;
    --circle-aspects-135-rgb-0: 255, 255, 255;
    --circle-aspects-144-rgb-0: 102, 187, 106;
    --circle-aspects-150-rgb-0: 255, 255, 255;
    --circle-aspects-180-rgb-0: 255, 255, 255;

    --circle-aspects-0-rgb-1: 64, 147, 244;
    --circle-aspects-30-rgb-1: 102, 187, 106;
    --circle-aspects-36-rgb-1: 112, 112, 112;
    --circle-aspects-40-rgb-1: 102, 187, 106;
    --circle-aspects-45-rgb-1: 239, 83, 80;
    --circle-aspects-60-rgb-1: 102, 187, 106;
    --circle-aspects-72-rgb-1: 112, 112, 112;
    --circle-aspects-80-rgb-1: 102, 187, 106;
    --circle-aspects-90-rgb-1: 239, 83, 80;
    --circle-aspects-100-rgb-1: 102, 187, 106;
    --circle-aspects-108-rgb-1: 112, 112, 112;
    --circle-aspects-120-rgb-1: 102, 187, 106;
    --circle-aspects-135-rgb-1: 239, 83, 80;
    --circle-aspects-144-rgb-1: 112, 112, 112;
    --circle-aspects-150-rgb-1: 239, 83, 80;
    --circle-aspects-180-rgb-1: 239, 83, 80;

    /*zodiacs*/
    --circle-zodiacs-fire-rgb-0: 255, 138, 101;
    --circle-zodiacs-water-rgb-0: 100, 181, 246;
    --circle-zodiacs-air-rgb-0: 149, 117, 205;
    --circle-zodiacs-land-rgb-0: 174, 213, 129;

    --circle-zodiacs-fire-rgb-1: 229, 115, 115;
    --circle-zodiacs-water-rgb-1: 100, 181, 246;
    --circle-zodiacs-air-rgb-1: 226, 238, 250;
    --circle-zodiacs-land-rgb-1: 129, 199, 132;

    --circle-zodiacs-fire-rgb-2: 229, 115, 115;
    --circle-zodiacs-water-rgb-2: 100, 181, 246;
    --circle-zodiacs-air-rgb-2: 144, 164, 174;
    --circle-zodiacs-land-rgb-2: 129, 199, 132;

    --circle-zodiacs-fire-rgb-3: 239, 83, 80;
    --circle-zodiacs-water-rgb-3: 139, 195, 74;
    --circle-zodiacs-air-rgb-3: 121, 134, 203;
    --circle-zodiacs-land-rgb-3: 255, 167, 38;

    --circle-zodiacs-fire-rgb-4: 255, 167, 38;
    --circle-zodiacs-water-rgb-4: 126, 87, 194;
    --circle-zodiacs-air-rgb-4: 77, 208, 225;
    --circle-zodiacs-land-rgb-4: 141, 110, 99;

    --scrollbar-color: #D9D9D95E;

    overflow-y: hidden;
}

body {
    height: 100%;
    position: relative;
    overflow-y: hidden;
}
