@font-face {
    font-family: "Apercu Pro";
    src: url("../assets/fonts/ApercuPro-Regular.woff2") format("woff2"), url("../assets/fonts/ApercuPro-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Apercu Pro";
    src: url("../assets/fonts/ApercuPro-Medium.woff2") format("woff2"), url("../assets/fonts/ApercuPro-Medium.woff") format("woff");
    font-weight: 600;
    font-style: normal;
}

html {
    --font-size: 16px;

    font-size: var(--font-size);
    min-height: 100%;
    color: var(--text-primary);

    height: 100%;
}

body {
    font-family: "Apercu Pro", sans-serif !important;
    margin: 0;
    min-height: 100%;
    height: 100%;
    background-color: var(--workspace-background);
    overflow-x: hidden;

    /*
  line-height: 1.5;
  overflow-x: hidden;
  overflow-y: auto;

  background: #191919;
  */
}

#root {
    height: 100%;
    /* height: 100%; */
    min-height: 100%;
    box-sizing: border-box;
    /* --col-width: 6.25%;
  --vertical-space: 3rem;
  --sidebar-space: 3rem;
  --header-height: 4rem;
  --footer-height: 20px;
  --animation-type: cubic-bezier(0.7,0,0.3,1);
  --animation-time: 0.3s; */
}

#onboarding {
  // position: relative;
  // top: 0;
  // left: 0;
  // width: 100vw;
  // min-width: 100vw;
  // height: 100vh;
  // min-height: 100vh;
  // z-index: 888;
}

#zmmtg-root,
#aria-notify-area {
    display: none;
}

/* *,
*::before,
*::after {
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  box-sizing: border-box;
} */

/* a {
  color: inherit;
  text-decoration: none;
} */

* {
    scrollbar-width: thin;
    scrollbar-color: black transparent;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: black;
    border: 1px solid gray;
    border-radius: 2px;
}

button,
input {
    font-family: inherit;
    font-size: inherit;
}

@media (max-width: 1440px) {
    html {
        --font-size: 14px;
    }
}

@media (-webkit-max-device-pixel-ratio: 1.25) and (max-width: 1600px), (max-width: 1366px) {
    html {
        --font-size: 12px;
    }
}

@media (max-width: 1280px) {
    html {
        --font-size: 10px;
    }
}

@media (-webkit-max-device-pixel-ratio: 1.5) and (max-width: 1366px), (max-width: 1024px) {
    html {
        --font-size: 10px;
    }
}

@media (max-width: 768px) {
    html {
        --font-size: 10px;
    }
}

@media (max-width: 480px) {
    html {
        --font-size: 16px;
    }
}

/* @media screen and (max-width: 1024px) and (orientation: portrait) {
  #root {
    display: none;
  }
  body:before {
    content: "↑ Переверните устройство в панорамный режим ";
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 999;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 100px;
  }
} */